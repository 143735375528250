import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getDataFromServer } from '../shared/utilities'
import EventsFilterable from '../components/EventsFilterable'
import LayoutListView from '../components/LayoutListView'
import missionSeek from '../images/mission-seek-square.png'
import Block from '../components/Block'
// import conferenceAd from '../images/NationalConference-large.png'

// @TODO - check if env variable and siteMetadata come through
const Events = ({ location }) => {
  const configID = '2YXlfab8d6bCai7MaIF5cu'
  // const MISSIONS_INTERLINK_ID = 902
  const [allEvents, setAllEvents] = useState([])

  useEffect(() => {
    const endpointEvents = 'https://www.missionone.com.au/api/events/'
    const token = process.env.GATSBY_MISSION_ONE_API_TOKEN

    if (!token) console.error('No MissionOne Token - fetch will fail')

    function getTime(time) {
      const arr = time.split('-')
      const day = +arr[0]
      const month = +arr[1] - 1
      const year = +arr[2]
      return new Date(year, month, day)
    }

    getDataFromServer(endpointEvents, token, false).then(event => {
      const events = event.sort((a, b) => (getTime(a.startDate) > getTime(b.startDate) ? 1 : -1))
      setAllEvents(events)
    })
  }, [])

  return (
    <LayoutListView path={location.pathname} configID={configID}>
      {/* <Link to="/events/missions-interlink-national-conference-2024/348">
        <img
          src={conferenceAd}
          alt="Conference Ad"
          style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
        />
      </Link> */}
      <EventsFilterable data={allEvents} title="Upcoming Events" />
      <br />
      <h2 style={{ paddingTop: '40px' }}>Upcoming Member/Associate Events</h2>
      <div className="row">
        <div className="col-md-3">
          <a href="https://www.missionseek.com.au/events/">
            <img className="photo-effect" src={missionSeek} alt="MissionSeek" />
          </a>
        </div>
        <div className="col-md-9">
          <Block contentfulID="1t8U1LmndjaGpvzScSmaBi" hLevel="" />
        </div>
      </div>
    </LayoutListView>
  )
}

export default Events

Events.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
